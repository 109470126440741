document.addEventListener("DOMContentLoaded", () => {
  if (document.body.classList.contains("home")) {
    // Récupère les collections et images
    const collections = document.querySelectorAll(".collection-toggler");
    const images = document.querySelectorAll(".collection-img");

    // Convertit NodeList en Array
    const collectionsArray = Array.from(collections);
    const imagesArray = Array.from(images);

    // Affiche la première image correspondante
    const firstCollection = collectionsArray[0];
    const firstImage = imagesArray.find(
      (image) => image.getAttribute("data-id") === firstCollection.getAttribute("data-id")
    );
    if (firstImage) {
      firstImage.classList.add("fade-in-image");
    }

    // Ajoute un événement click sur chaque collection-toggler
    collectionsArray.forEach((collection) => {
      collection.addEventListener("click", () => {
        // Supprime la classe fade-in-image de toutes les images
        imagesArray.forEach((img) => img.classList.remove("fade-in-image"));

        // Trouve et affiche l'image correspondante
        const targetImage = imagesArray.find(
          (image) => image.getAttribute("data-id") === collection.getAttribute("data-id")
        );
        if (targetImage) {
          targetImage.classList.add("fade-in-image");
        }
      });
    });
  }
});
