document.addEventListener("DOMContentLoaded", () => {
  if(document.querySelector("body").classList.contains("nos-expertises")) {
    if ($(window).width() > 992) {
      // Récupère les titres de liens
      let titles = $(".banner-link-title");

      // Récupère les images
      let images = $(".banner-link-img");

      let titles_array = Array.from(titles);
      let images_array = Array.from(images);

      let activeImage = images_array.filter(image => image.getAttribute("data-id") == titles_array[0].getAttribute("data-id") );

      // Affiche la première image
      activeImage[0].classList.add("fade-in-image");

      // Affiche l'image correspondante au titre cliqué
      titles_array.map((title) => {
        title.addEventListener("mouseover", () => {
          images_array.map((image) => {
            if(title.getAttribute("data-id") == image.getAttribute("data-id")) {
              $(".fade-in-image").removeClass("fade-in-image");
              image.classList.add("fade-in-image");
            }
          });
        });
      });
    }
  }
});
