/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

// Certifications
var certification_slider = new Swiper(".slider-certification .swiper", {
  grabCursor: true,
  spaceBetween: 25,
  slidesPerView: 1.5,
  centeredSlides: true,
  loop: true,
  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      centeredSlides: false,
      slidesPerView: 4,
      loop: false,
    },
  },
});


var paragraphe_slider = new Swiper(".img-paragraphe-swiper", {
  grabCursor: true,
  loop: "true",
  slidesPerView: "auto",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

var first_img_text_slider = new Swiper(".first-img-text-swiper", {
  grabCursor: true,
  loop: "true",
  slidesPerView: "auto",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

var second_img_text_slider = new Swiper(".second-img-text-swiper", {
  grabCursor: true,
  loop: "true",
  slidesPerView: "auto",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

var third_img_text_slider = new Swiper(".third-img-text-swiper", {
  grabCursor: true,
  loop: "true",
  slidesPerView: "auto",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

var countries_slider = new Swiper(".countries-swiper", {
  grabCursor: true,
  loop: "true",
  slidesPerView: "auto",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

// Pourcentages
var percentage_slider = new Swiper(".slider-percentage .swiper", {
  grabCursor: true,
  spaceBetween: 10,
  slidesPerView: 1.3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 'auto',
      spaceBetween: 30,
    },
  },
});

/* Slider */
var carousel_slide = new Swiper('.carousel-swiper', {
  slidesPerView: 1.25,
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.carousel-next',
    prevEl: '.carousel-prev',
  },

  breakpoints: {
    992: {
      spaceBetween: 40,
    },
  },
});

/* Actus */
var actus_slide = new Swiper('.actus-swiper', {
  slidesPerView: "auto",
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.actus-next',
    prevEl: '.actus-prev',
  },
});

/* Engagements */
var engagements_slide = new Swiper('.engagements-swiper', {
  slidesPerView: 2,
  centeredSlides: false,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/* Vins */
var wine_slider = new Swiper('.wines-swiper', {
  slidesPerView: 2,
  centeredSlides: false,
  grabCursor: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
});

var values_slide = new Swiper('.values-swiper', {
  slidesPerView: "auto",
  centeredSlides: false,
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  slideToClickedSlide: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    renderFraction: function (currentClass, totalClass) {
      return (
        "<div class='home-hero-pagination'>" +
        '<span class="' + currentClass + '"></span>' +
        " <div class='pagination-tiret'></div> " +
        '<span class="' + totalClass + '"></span>' +
        "</div>"
      );
    },
  },

  on: {
    init: function(swiper) {
      setTimeout(() => { // Assurer que l'init est terminé
        updateActiveImage(swiper);
      }, 100);
    },

    slideChangeTransitionStart: function(swiper) {
      updateActiveImage(swiper);
    },
  },
});

function updateActiveImage(swiper) {
  let isDesktop = window.innerWidth >= 768;

  // Récupère la slide active
  let activeSlide = document.querySelector(".values-slide.swiper-slide-active");

  // Si on est en desktop, on prend la slide suivante
  let targetSlide = isDesktop
    ? activeSlide.nextElementSibling // Slide suivante
    : activeSlide; // Slide actuelle

  let activeSlideIndex = targetSlide ? targetSlide.getAttribute("data-id") : null;

  if (!activeSlideIndex) return;

  // Récupère toutes les images
  let images = document.querySelectorAll(".header-img");

  // Supprime l'effet de l'image précédente **uniquement si le parent n'a pas .collections-image-wrapper**
  document.querySelectorAll(".fade-in-image").forEach(img => {
    if (!img.closest(".collections-image-wrapper")) {
      img.classList.remove("fade-in-image");
    }
  });

  // Trouve et active la bonne image
  images.forEach(image => {
    if (image.getAttribute("data-id") === activeSlideIndex) {
      image.classList.add("fade-in-image");
    }
  });
}



/* eslint-enable no-unused-vars */
